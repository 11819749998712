import { useCallback, useState } from 'react'
import { IUseSendOrder } from '../views/calculator/interface'
import fetchAPI from '../../lib/utils/fetch-api'
import { sendCt } from 'src/lib/utils/ct'
import {insetNoteToCollection} from "../views/calculator/stage-quiz/utils/insert-note";
import {insetFileToColletion} from "../views/calculator/stage-quiz/utils/insert-file";
import moment from "moment";

export const useSendOrder: IUseSendOrder = () => {
  const [isSending, setIsSending] = useState(false)
  const [errors, setErrors] = useState<Record<string, any>[]>([])

  const handleSendOrder = useCallback(async (orderData, consultationData) => {
    setIsSending(true)
    let result = {}

    try {
      const requestData = {
        ...orderData,
        'source-appeal': 'Подбор остекления - сайт',
        token: 'ZHNhc2Rha3MgaXNuZCBhaWpzNjU0ZjhzZDRmIHNkIDY1ZTFydw=='
      }

      requestData['recipient-phone'] = requestData['phone']

      if (consultationData) {
        requestData['source-code'] = 'consultation'
        requestData['recipient-phone'] = consultationData['phone']
        requestData['recipient-name'] = consultationData['name']
        requestData['email'] = consultationData['email']
      }
      console.log('requestData', requestData)
      result = await fetchAPI('/api/create-case', {
        method: 'POST',
        body: JSON.stringify(requestData),
      })
      const targetId = result?.['data']?.['id']
       console.log('sc 677', result, typeof targetId)
       console.log({orderData, requestData, consultationData})
      if (Number.isInteger(targetId)) {
        if (orderData?.['set-case-to-dealer'] !== true) {
          sendCt({name: consultationData['name'], phone: consultationData['phone']})

          window?.roistatGoal?.reach?.({
            leadName: `Форма в заказе "Оформить с помощью специалиста"`,
            text: `Ссылка на страницу: ${window.location.href}`,
            name: consultationData['name'],
            phone: consultationData['phone'],
          });

          await fetchAPI('web-hooks/set-case-to-dealer', {
            method: 'POST',
            body: JSON.stringify({'target-id': targetId})
          })
        }

        let content = ''
        if (orderData['quiz']) {
          content = '<b>Ответы:</b> \r\n\r\n'
          for (const question in orderData['quiz']) {
            const answer = orderData['quiz'][question].replace(/(\r\n|\n|\r)/gm, "").replace(/ +(?= )/g, '')
            content += `Вопрос: ${question}? \r\nОтвет: ${answer} \r\n\r\n`
          }
        }

        if (consultationData?.['description']) {
          content += '<b>Описание задачи: </b>' + consultationData['description'] + '\r\n\r\n'
        }

        if (consultationData?.['time']) {
          content += '<b>Удобное время для звонка: </b>' + consultationData['time']
        }

        if (content) {
          const date = moment().toISOString()
          await insetNoteToCollection({date, content: content, author: 0}, targetId)
        }

        if (consultationData?.['files']) {
          for (const file of consultationData['files']) {
            console.log('file', file)
            await insetFileToColletion(file, targetId)
          }
        }
      }

      if (result && result['errors']) {
        setErrors(result['errors'])
      }

      setIsSending(false)
    } catch (e) {
      console.error(e)
    }

    return result['errors'] || result['data']
  }, [])

  const handleUpdateOrder = useCallback(async (orderId, orderData) => {
    setIsSending(true)
    let result = {}

    try {
      result = await fetchAPI(`/api/case/${orderId}`, {
        method: 'POST',
        body: JSON.stringify({
          ...orderData,
          'source-appeal': 'Оформление обращения',
        }),
      })

      if (result && result['errors']) {
        setErrors(result['errors'])
      }
    } catch (e) {
      console.error(e)
    }

    setIsSending(false)

    return result['errors'] || result['data']
  }, [])

  return {
    isSending,
    errors,
    createOrder: handleSendOrder,
    updateOrder: handleUpdateOrder,
  }
}
